import * as React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { TileRow } from "../../components";
import styled from "styled-components";
import { navigate } from "@reach/router";
import { DesktopView } from "../../components/layout-helpers";
import { MobileView } from "../../components/layout-helpers";
import { DarkButton } from "../../components/buttons";
import masonryImg from "../../images/masonry.jpg";
import { breakpoints } from '../../constants';

const masonryText = `We offer masonry brick and block services. Request a free estimate today!`;

const content = {
  services: {
    title: "Masonry Services",
    image: masonryImg,
    buttonText: "Request Estimate",
    to: "/estimate",
  },
  seo: {
    siteUrl: "https://hconcoinc.com/services/masonry",
    title: "Masonry Services",
    keywords: [
      `masonry`,
      `brick`,
      `block`,
      `brick & block`,
      `concrete`,
      `foundations`,
      `hconco`,
      `nashville`,
    ],
    description: masonryText,
  },
};

const FlexContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const ServicesContainer = styled.div`
  margin-top: 4rem;
  margin-bottom: 4rem;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 768px) {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const StyledDesktopView = styled(DesktopView)`
  max-width: 90%;
`;

const StyledMobileView = styled(MobileView)`
  margin-bottom: 4rem;
`;

const FlexContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

const FlexItemMobile = styled.div`
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
`;

const HeadlineText = styled.h1`
  color: ${(props) => props.theme.textGrey};
  font-size: 2rem;
  margin: 1rem 0 1rem 0;

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 2.5rem;
  }
`;

const BodyText = styled.div`
  color: ${(props) => props.theme.textGrey};
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 4rem;
`;

const Section = styled.div`
  width: 85%;
  min-height: 400px;
  margin: auto;

  @media (min-width: ${breakpoints.tablet}px) {
    width: 70%;
  }
`;

const ImageSection: any = styled(Section)`
  background-image: ${(props: any) => `url(${props.background})`};
  width: 100%;
  min-height: 400px;
  width: 100%;
  background-size: cover;
  background-position-y: center;
`;



const MasonryPage = () => (
  <Layout>
    <SEO
      title={content.seo.title}
      keywords={content.seo.keywords}
      description={content.seo.description}
      siteUrl={content.seo.siteUrl}
    />
    <ServicesContainer>
      <StyledDesktopView>
        <TileRow
          title={content.services.title}
          buttonText={content.services.buttonText}
          image={content.services.image}
          buttonOnClick={() => navigate(content.services.to)}
        >
          <FlexContainer>
          <div dangerouslySetInnerHTML={{ __html: masonryText }}/>
          </FlexContainer>
        </TileRow>
      </StyledDesktopView>
    </ServicesContainer>

    <StyledMobileView>
      <ImageSection background={masonryImg} />
      <Section>
        <FlexContainerColumn>
          <FlexItemMobile>
            <HeadlineText>{content.services.title}</HeadlineText>
          </FlexItemMobile>
          <FlexItemMobile>
            <BodyText dangerouslySetInnerHTML={{__html: masonryText}} />
          </FlexItemMobile>
          <FlexItemMobile>
            <DarkButton onClick={() => navigate(content.services.to)}>
              {content.services.buttonText}
            </DarkButton>
          </FlexItemMobile>
        </FlexContainerColumn>
      </Section>
    </StyledMobileView>
  </Layout>
);

export default MasonryPage;
